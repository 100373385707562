import Axios from 'axios';
import * as React from 'react';

import { Error } from '../../components/Error';
import { Loading } from '../../components/Loading';
import { API_URL } from '../../constants';

export class SingleVideoPage extends React.Component<any, any> {
  public state = {
    videoUri: '',

    loading: true,
    error: null,
  };

  public handleQuillChange = (value: string) => {
    this.setState({ content: value });
  }

  public componentDidMount() {
    this.fetch();
  }

  public fetch = async () => {
    try {
      const res = await Axios.get(
        `${API_URL}/videos/${this.props.match.params.id}`,
      );
      this.setState({
        loading: false,
        videoUri: res.data.data.videoUri,
      });
    } catch (error) {
      this.setState({ loading: false, error: error.response.data.message });
    }
  }

  public handleInputChange = event => {
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;

    this.setState({
      [name]: value,
    });
  }

  public handleUpdate = (e: any) => {
    e.preventDefault();
    const { videoUri } = this.state;
    try {
      Axios.put(
        `${API_URL}/videos/${this.props.match.params.id}`,
        {
          videoUri,
        },
        {
          headers: {
            Authorization: localStorage.getItem('token'),
          },
        },
      );
      this.props.history.push('/dashboard/videos');
    } catch (error) {
      this.setState({ error: error.response.data.message });
      window.scroll(0, 0);
    }
  }

  public handleDelete = async (e: any) => {
    e.preventDefault();
    const confirm = window.confirm('Are you sure?');
    if (confirm) {
      try {
        await Axios.delete(`${API_URL}/videos/${this.props.match.params.id}`, {
          headers: {
            Authorization: localStorage.getItem('token'),
          },
        });
        // this.props.history.push(`/dashboard/videos`);
      } catch (error) {
        this.setState({ error: error.response.data.message });
        window.scroll(0, 0);
      }
      return;
    }
  }

  public render() {
    if (this.state.loading) {
      return <Loading />;
    }

    if (this.state.error) {
      return (
        <div className="card">
          <div className="card-body">{JSON.stringify(this.state.error)}</div>
        </div>
      );
    }
    return (
      <div className="card">
        <div className="card-body">
          <h5 className="card-title">Update Video</h5>
          {this.state.error && (
            <Error error={JSON.stringify(this.state.error)} />
          )}
          <form>
            <div className="form-group">
              <label>Video URL</label>
              <input
                type="text"
                name="videoUri"
                placeholder="Video URL"
                className="form-control"
                value={this.state.videoUri}
                onChange={this.handleInputChange}
              />
            </div>
            <div
              style={{
                display: 'flex',
                flex: 1,
                justifyContent: 'space-between',
                alignItems: 'center',
              }}
            >
              <button className="btn btn-primary" onClick={this.handleUpdate}>
                Update
              </button>
              <button className="btn btn-danger" onClick={this.handleDelete}>
                Delete
              </button>
            </div>
          </form>
        </div>
      </div>
    );
  }
}

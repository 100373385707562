import * as React from 'react';

import Axios from 'axios';
import ReactQuill from 'react-quill';

import { Error } from '../../components/Error';

import 'react-quill/dist/quill.snow.css';
import { API_URL } from '../../constants';

export class NewEventPage extends React.Component<any, any> {
  public state = {
    title: '',
    image: '',
    description: '',
    startTime: '',
    endTime: '',
    date: '',
    address: '',
    attendingCount: 0,

    error: null,
  };

  public modules = {
    toolbar: [
      [{ header: [1, 2, 3, 4, 5, 6] }],
      ['bold', 'italic', 'underline', 'strike', 'blockquote'],
      [
        { list: 'ordered' },
        { list: 'bullet' },
        { indent: '-1' },
        { indent: '+1' },
      ],
      ['link', 'video'],
      ['clean'],
    ],
  };

  public formats = [
    'header',
    'bold',
    'italic',
    'underline',
    'strike',
    'blockquote',
    'list',
    'bullet',
    'indent',
    'link',
    'video',
  ];

  public handleQuillChange = (value: string) => {
    this.setState({ description: value });
  }

  public handleInputChange = (event: any) => {
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;

    this.setState({
      [name]: value,
    });
  }

  public handleSubmit = async (e: any) => {
    e.preventDefault();
    const {
      title,
      image,
      description,
      startTime,
      endTime,
      date,
      address,
    } = this.state;

    try {
      await Axios.post(
        `${API_URL}/events`,
        {
          title,
          image,
          description,
          startTime,
          endTime,
          date,
          address,
        },
        {
          headers: {
            Authorization: localStorage.getItem('token'),
          },
        },
      );

      this.props.history.push(`/dashboard/events`);
    } catch (error) {
      this.setState({ error: error.response.data.message });
      window.scroll(0, 0);
    }
  }

  public render() {
    return (
      <div className="card">
        <div className="card-body">
          <h5>New Event</h5>
          {this.state.error && (
            <Error error={JSON.stringify(this.state.error)} />
          )}
          <form onSubmit={e => this.handleSubmit(e)}>
            <div className="form-group">
              <label>Title</label>
              <input
                type="text"
                name="title"
                placeholder="Title"
                value={this.state.title}
                className="form-control"
                onChange={this.handleInputChange}
              />
            </div>

            <div className="form-group">
              <label>Image</label>
              <input
                type="text"
                name="image"
                placeholder="Image"
                value={this.state.image}
                className="form-control"
                onChange={this.handleInputChange}
              />
            </div>

            <div className="row" style={{ padding: '1em' }}>
              <div className="form-group" style={{ marginRight: 24 }}>
                <label>Start Time</label>
                <input
                  type="text"
                  name="startTime"
                  placeholder="Start Time"
                  value={this.state.startTime}
                  className="form-control"
                  onChange={this.handleInputChange}
                />
              </div>
              <div className="form-group" style={{ marginRight: 24 }}>
                <label>End Time</label>
                <input
                  type="text"
                  name="endTime"
                  placeholder="End Time"
                  value={this.state.endTime}
                  className="form-control"
                  onChange={this.handleInputChange}
                />
              </div>
              <div className="form-group">
                <label>Date</label>
                <input
                  type="text"
                  name="date"
                  placeholder="Date"
                  value={this.state.date}
                  className="form-control"
                  onChange={this.handleInputChange}
                />
              </div>
            </div>

            <div className="form-group">
              <label>Description</label>
              <ReactQuill
                modules={this.modules}
                formats={this.formats}
                value={this.state.description}
                onChange={(val: any) => this.handleQuillChange(val)}
                style={{ height: 500, marginBottom: 100 }}
              />
            </div>

            <button className="btn btn-primary" type="submit">
              Create
            </button>
          </form>
        </div>
      </div>
    );
  }
}

import * as React from 'react';
import { Route, Switch } from 'react-router';

// Containers
import { PrivateRoute } from '../../containers/PrivateRoute';

// Pages
import { AllEventsPage } from '../../pages/AllEventsPage';
import { AllVideosPage } from '../../pages/AllVideosPage';
import { Dashboard } from '../../pages/Dashboard';
import { LoginPage } from '../../pages/LoginPage';
import { NewEventPage } from '../../pages/NewEventPage';
import { NewVideoPage } from '../../pages/NewVideoPage';
import { NotFoundPage } from '../../pages/NotFoundPage';
import { SingleEventPage } from '../../pages/SingleEventPage';
import { SingleVideoPage } from '../../pages/SingleVideoPage';

export const Root = () => (
  <Switch>
    <Route exact path="/" component={LoginPage} />
    <PrivateRoute exact path="/dashboard" component={Dashboard} />

    {/* Events */}
    <PrivateRoute exact path="/dashboard/events" component={AllEventsPage} />
    <PrivateRoute exact path="/dashboard/events/new" component={NewEventPage} />
    <PrivateRoute
      exact
      path="/dashboard/events/:id"
      component={SingleEventPage}
    />

    {/* Videos */}
    <PrivateRoute exact path="/dashboard/videos" component={AllVideosPage} />
    <PrivateRoute exact path="/dashboard/videos/new" component={NewVideoPage} />
    <PrivateRoute
      exact
      path="/dashboard/videos/:id"
      component={SingleVideoPage}
    />

    <Route component={NotFoundPage} />
  </Switch>
);

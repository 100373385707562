import * as React from 'react';
import { NavLink, withRouter } from 'react-router-dom';

const NavItem = ({ name, location, pathName }) => (
  <li className={pathName.includes(location) ? 'active' : ''}>
    <NavLink to={location} className="nav-link">
      {name}
    </NavLink>
  </li>
);

const minimizeSidebar = () => {
  document.body.classList.toggle('sidebar-mini');
};

const SidebarComponent = ({ location }) => (
  <div className="sidebar" data-color="orange">
    <div>
      <div className="navbar-minimize">
        <button
          id="minimizeSidebar"
          onClick={minimizeSidebar}
          className="btn btn-primary"
        >
          <i className="now-ui-icons text_align-center visible-on-sidebar-regular" />
          <i className="now-ui-icons design_bullet-list-67 visible-on-sidebar-mini" />
        </button>
      </div>
    </div>

    <div className="sidebar-wrapper">
      <ul className="nav">
        <NavItem
          name="Events"
          location="/dashboard/events"
          pathName={location.pathname}
        />
        <NavItem
          name="Videos"
          location="/dashboard/videos"
          pathName={location.pathname}
        />
      </ul>
    </div>
  </div>
);

export const Sidebar = withRouter(SidebarComponent);

import Axios from 'axios';
import * as React from 'react';

import { Error } from '../../components/Error';

import { API_URL } from '../../constants';

export class NewVideoPage extends React.Component<any, any> {
  public state = {
    videoUri: '',

    error: null,
  };

  public handleInputChange = (event: any) => {
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;

    this.setState({
      [name]: value,
    });
  }

  public handleSubmit = async (e: any) => {
    e.preventDefault();
    const { videoUri } = this.state;

    try {
      await Axios.post(
        `${API_URL}/videos`,
        {
          videoUri,
        },
        {
          headers: {
            Authorization: localStorage.getItem('token'),
          },
        },
      );

      this.props.history.push(`/dashboard/videos`);
    } catch (error) {
      this.setState({ error: error.response.data.message });
      window.scroll(0, 0);
    }
  }

  public render() {
    console.log(this.state);
    return (
      <div className="card">
        <div className="card-body">
          <h5>New Video</h5>
          {this.state.error && (
            <Error error={JSON.stringify(this.state.error)} />
          )}
          <form onSubmit={e => this.handleSubmit(e)}>
            <div className="form-group">
              <label>Video</label>
              <input
                type="text"
                name="videoUri"
                placeholder="Video"
                value={this.state.videoUri}
                className="form-control"
                onChange={this.handleInputChange}
              />
            </div>

            <button className="btn btn-primary" type="submit">
              Create
            </button>
          </form>
        </div>
      </div>
    );
  }
}

import Axios from 'axios';
import moment from 'moment';
import * as React from 'react';
import { Link } from 'react-router-dom';
import ReactTable from 'react-table';
import { Error } from '../../components/Error';
import { Loading } from '../../components/Loading';
import { API_URL } from '../../constants';

export class AllEventsPage extends React.Component {
  public state = {
    loading: true,
    error: null,
    data: null as any,
  };

  public componentDidMount() {
    this.fetch();
  }

  public fetch = async () => {
    try {
      const res = await Axios.get(`${API_URL}/events`);
      this.setState({ loading: false, data: res.data });
    } catch (error) {
      this.setState({ loading: false, error: error.response.data.message });
    }
  }

  public render() {
    const { loading, error } = this.state;

    if (loading) {
      return <Loading />;
    }

    if (error) {
      return <Error error={error} />;
    }

    const d = this.state.data.data.map((sd: any) => {
      return {
        title: <Link to={`/dashboard/events/${sd._id}`}>{sd.title}</Link>,
        createdAt: moment(sd.createdAt).format('MM-DD-YYYY'),
      };
    });

    return (
      <div className="card">
        <div className="row">
          <div className="col-xs-12 col-md-12 col-lg-12">
            <div className="card-header">
              <Link to="/dashboard/events/new">
                <button className="btn btn-primary">New Event</button>
              </Link>
            </div>
            <div className="card-body">
              <ReactTable
                data={d}
                filterable
                columns={[
                  {
                    Header: 'Title',
                    accessor: 'title',
                  },
                  {
                    Header: 'Created At',
                    accessor: 'createdAt',
                  },
                ]}
                defaultPageSize={20}
                showPaginationTop={false}
                showPaginationBottom={true}
                className="-striped -highlight"
              />
            </div>
          </div>
        </div>
      </div>
    );
  }
}
